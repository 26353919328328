import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { store } from '@/store/store';
import ReduxToastr from './ReduxToast';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const MainProvider: FC<any> = ({ children, Component }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReduxToastr />
        {children}
      </QueryClientProvider>
    </Provider>
  );
};

export default MainProvider;
